import { useState } from "react";
import "./App.css";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="container">
      <div className={`envelope ${isOpen ? "open" : ""}`} onClick={() => setIsOpen(true)}>
        <div className="flap"></div>
        <div className="front-text">Para el amor de mi vida ❤️</div>
        <div className="letter">
          <p>hola, algo así decia la carta, sinceramente lo intenté pero soy malísimo haciendo cartas ya que nunca lo hice pero lo intenté. espero algún día hacer una y poder verte feliz pero lo voy a seguir intentando un día, voy a hacerte una hermosa que te va a encantar porque sé que me va a salir. </p>
        <p>sinceramente gracias desde que te conocí cambie mi actitud y demás, quiero agradecerte por ser la única persona que siempre está al lado mio más allá de que no nos veamos mucho siento que siempre te tengo al lado, los detalles que tenés conmigo me hacen muy feliz desde que te conocí sentí lo que es amar de verdad. te amé siempre y te voy a seguir amando, sos un corazón de persona sinceramente te quiero y te amo igual que a mis viejos, no tengo palabras como para agradecerte todo lo que hiciste por mí y las veces que te preocupaste y me hablaste, mi vieja está muy feliz de que seas mi novia, siempre me dice que chicas como vos no se consiguen hoy en día, sos de las pocas que quedan o casi nulas. siempre sos mi motivación no sé si para vivir pero para seguir adelante cuando me pasan cosas, estoy muy agradecido con que hayas aparecido en mí vida y me puedas haber ayudado en todo, sos la persona que más amo actualmente, y voy a seguir amando hasta que me muera, solo quiero que pienses que cuando me levanto solo me importa decirte los buenos días y que me digas que estás bien. sos literalmente lo que me hace feliz cada día, siempre estás ahí, ya lo sé que no estás físicamente, pero siento como si estuvieses al lado mio, estar con vos dos horas se convierten en diez minutos cuando con otra persona son eternas, sos la chica que siempre soñé y te voy a cuidar con lo que tenga en mí mano, soy capaz de hacer cualquier cosa por verte feliz, no me importa nada, solo quiero verte feliz ya yo después me las arreglo, de acá al futuro, solo me veo con vos viviendo juntos y con nadie más, te amo ♾️, amor, sos lo que siempre soñé.</p>
        </div>
      </div>
      <div className="floating-hearts"></div>
    </div>
  );
}

export default App;